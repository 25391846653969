.app-main {
    min-height: 100vh;
    background-color: var(--background);
    color: var(--light);

    .main-body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .btn {
        background-color: var(--primary);
        border-color: var(--secondary);
        color: var(--accent);

        &:hover {
            background-color: var(--success);
            border-color: var(--success);
        }
    }

    .card {
        background-color: var(--primary);
        color: var(--light);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 1rem;
        padding: 1rem;
        text-align: center;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        .card-header {
            color: var(--accent);
            background-color: transparent;
        }

        .card-subtitle {
            color: var(--accent);
        }

        .card-text {
            color: var(--accent);
        }

        .card-footer {
            color: var(--accent);
            background-color: transparent;
        }

        .card-link {
            color: var(--terciary);

            &:hover {
                color: var(--success);
            }
        }
    }

    .form-label {
        color: var(--accent);
    }
}