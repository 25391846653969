.navbar {
    background-color: var(--primary);
    color: var(--light);

    .navbar-brand,
    .nav-link {
        color: var(--accent);
    }

    .company-logo-image {
        border-radius: 10px;
        height: 30px;
        width: 30px;
        background-color: var(--light);
    }

    .nav-theme-container {
        display: flex;
        justify-content: flex-end;
        max-width: fit-content;
    }

    .nav-translation-container {
        display: flex;
        justify-content: flex-end;
        max-width: fit-content;

        .nav-translation-dropdown .dropdown-menu {
            background-color: var(--primary);
        }
    }
}
