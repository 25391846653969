.company-card {
    max-width: 100vh;
    max-height: 100vh;

    .card-subtitle {
        padding-top: 1rem;
    }

    .company-logo-image {
        margin-top: 1rem;
        border-radius: 10px;
        height: 300px;
        width: 300px;
        background-color: var(--light);
    }

    .social-links {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .social-link {
        text-decoration: none;
        transition: color 0.3s ease;
    }
}
