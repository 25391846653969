.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .contact-card {
        max-width: 100vh;
        max-height: 100vh;
    }

    .contact-email-alert {
        max-width: 100vh;
        margin: 1rem;
        padding: 1rem;
    }
}