.client-feedback-card {
    max-width: 100vh;
    max-height: 100vh;

    .card-subtitle {
        padding-top: 1rem;
    }

    .client-feedback-logo-image {
        margin-top: 1rem;
        border-radius: 10px;
        height: 100px;
        width: 100px;
        background-color: var(--light);
    }
}