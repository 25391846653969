$theme-colors-light: (
    'primary': rgb(126, 88, 116),
    'secondary': rgb(104, 242, 180),
    'terciary': rgb(233, 122, 118),
    'success': rgb(27, 135, 97),
    'info': rgb(48, 188, 237),
    'warning': rgb(201, 193, 36),
    'danger': rgb(214, 37, 24),
    'light': rgb(236, 240, 244),
    'dark': rgb(52, 58, 64),
    'background': rgb(201, 183, 182),
    'accent': rgb(87, 184, 141),
);
$theme-colors-dark: (
    'primary': rgb(201, 183, 182),
    'secondary': rgb(52, 121, 90),
    'terciary': rgb(116, 61, 59),
    'success': rgb(13, 67, 48),
    'info': rgb(24, 94, 118),
    'warning': rgb(100, 96, 18),
    'danger': rgb(107, 18, 12),
    'light': rgba(236, 240, 244, 0.454),
    'dark': rgb(26, 29, 32),
    'background': rgb(63, 44, 58),
    'accent': rgb(43, 92, 70),
);

// Map color variables to CSS custom properties
:root {
    @each $name, $color in $theme-colors-light {
        --#{$name}-light: #{$color};
    }

    @each $name, $color in $theme-colors-dark {
        --#{$name}-dark: #{$color};
    }
}
